// Main theme colors
$main-theme: #3492d8;

// Import all the nessesary common files for aachen
@import "_imports-aachen";

// Custom Styles for KAB Aachen

// Logo runter, zum überlappen
.ap-header .bis-logogroup {
  z-index: 999;
  margin-bottom: -100px;
}
